import React, { useState, useEffect } from "react";
import FormLabel from "../../../components/Custom/FormLabel";
import CustomForm from "../../../components/antdCustom/CustomForm";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  message,
} from "antd";

import useAPI from "../../../hooks/useAPI";
import { updateTranslateHd, addTranslateHd } from '../../../service/api/publicAPI';

import { systemMap } from '../../../util/constants'

const ModalContainer = ({ set_s_showModal, s_editData, set_s_editData }) => {
  const call_addTranslateHd = useAPI(addTranslateHd)
  const call_updateTranslateHd = useAPI(updateTranslateHd)

  const [form] = Form.useForm();

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "edit";

    if (type === "create") {
      call_addTranslateHd.request(values)
    }

    if (type === "edit") {
      call_updateTranslateHd.request(values)
    }
  };

  // 新增
  useEffect(() => {
    if(call_addTranslateHd.status === 'suc') {
      message.success(call_addTranslateHd.msg);
      set_s_showModal(false);
    }
    else if(call_addTranslateHd.status === 'err') {
      message.error(call_addTranslateHd.msg);
      set_s_showModal(false);
    }
  }, [call_addTranslateHd.status]);

  // 修改
  useEffect(() => {
    if(call_updateTranslateHd.status === 'suc') {
      message.success(call_updateTranslateHd.msg);
      set_s_showModal(false);
    }
    else if(call_updateTranslateHd.status === 'err') {
      message.error(call_updateTranslateHd.msg);
      set_s_showModal(false);
    }
  }, [call_updateTranslateHd.status]);

  const handleCancel = () => {
    set_s_showModal(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, s_editData]);

  // 卸載時清空資料
  useEffect(() => {
    return () => set_s_editData({});
  }, []);

  return (
    <CustomForm
      onFinish={onSubmit}
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      initialValues={{
        ...s_editData,
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={8}>
          <Form.Item 
            label={<FormLabel>分頁ID</FormLabel>}
            name="pageID"
            rules={[{ required: true, message: '請填寫分頁ID!' }]}
          >
            <Input 
              disabled={Object.keys(s_editData).length > 0}
              placeholder="請輸入分頁ID"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item 
            label={<FormLabel>分頁名稱</FormLabel>}
            name="pageNM"
            // rules={[{ required: true, message: '請填寫分頁名稱!' }]}
          >
            <Input 
              placeholder="請輸入分頁名稱"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item 
            label={<FormLabel>所屬系統</FormLabel>}
            name="sys"
            rules={[{ required: true, message: '請填寫所屬系統!' }]}
          >
            <Select
              allowClear
              placeholder="請選擇所屬系統"
            >
              {
                Object.entries(systemMap).map(([key, value]) => {
                  return <Select.Option value={key}>{`${key}-${value}`}</Select.Option>
                })
              };
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            label={<FormLabel>備註</FormLabel>}
            name="note"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col offset={12} span={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="large"
              style={{ marginRight: "20px", borderRadius: "5px" }}
              onClick={handleCancel}
            >
              取消
            </Button>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ borderRadius: "5px" }}
              >
                確定
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </CustomForm>
  );
};

export default ModalContainer;
