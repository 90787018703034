const apiBasic = {
  // 測試環境
//   url: "localhost:5555",
//   conn: "http",

  // 佈鼠環境🐭
  url: "translate.smartauto.com.tw",
  conn: "https",

  // 部屬內網
  // url: "172.16.1.20:7351/pds",
  // conn: "http",
};

export default apiBasic;
