/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import styled from "styled-components";
import { Layout, Spin, message } from "antd";

import StoreContext from "../Context/StoreContext";
import { PermissionAuth } from "./PermissionAuth";

import ErrorPage from "../../pages/ErrorPage";
import MyHeader from "./Header";
import MyNavbar from "./Navbar";

import { getUserPerm } from "../../service/api/publicAPI";
import useAPI from "../../hooks/useAPI";

import { parseAuthJSON } from "../../util/json"

// 全域的state
export const HomeContent = React.createContext();

//get basicAPI
const MainLayout = styled(Layout)`
  transition: 0.3s;
  height: 100vh;
  .header {
    transition: 0.3s;
    z-index: ${(props) => (props.navbarstate === "open" ? "0" : "99")};
    padding-left: ${(props) =>
    props.navbarstate === "open"
      ? "200px"
      : props.navbarstate === "close"
        ? "0px"
        : "80px"};
  }
  .navbar {
    z-index: 100;
  }
  .content {
    transition: 0.3s;
    height: calc(100vh - 60px);
    margin-top: 60px;
    /* padding: 15px; */
    /* +16px 表示原本寬度要計算 */
    padding-left: ${({ navbarstate }) =>
    navbarstate === "open" ? "220px" : "0px"};
  }
  
`;

const { Content } = Layout;

const MyLayout = (props) => {

  const [navbarState, setNavbarState] = useState("close");
  // const [locale, setLocale] = useState("CN");
  const [s_CheckAuth, set_s_CheckAuth] = useState([]);

  const [c_PNs, set_c_PNs] = useState([]);
  const [is_spinning, set_is_spinning] = useState(false);

  //navbar ctrl
  let location = useLocation();
  let navigate = useNavigate();

  const { storeData, setStoreData } = useContext(StoreContext);

  const call_getUserPerm = useAPI(getUserPerm);

  // 取得員工權限、起始資料
  useEffect(() => {
    call_getUserPerm.request();
  }, []);

  useEffect(() => {
    if (call_getUserPerm.status === "suc") {
      const dataWithJSON = parseAuthJSON(call_getUserPerm.data)

      setStoreData({ ...dataWithJSON });

      let { CheckAuth, path_array } = PermissionAuth(dataWithJSON);

      set_s_CheckAuth(CheckAuth);
      const curr_pathname = location.pathname;

      if (curr_pathname === '/') {

        // 如果使用者沒有任何權限就導入 <ErrorPage />
        if (CheckAuth.length === 0) return;
        // 將使用者導入他有權限的第一個route
        navigate(CheckAuth[0].path ?? CheckAuth[0].children[0].path);
      }
      else {
        // 用來判斷使用者是否有權限進入目前的URL
        const hasAuth = path_array.find(item => item === curr_pathname) ? true : false;

        // 使用者沒有權限進入目前的URL 也沒有權限進入任何URL
        if (hasAuth === false && path_array.length === 0) return;

        // 使用者沒有權限進入目前的URL，所以將使用者導入他有權限的第一個URL
        if (hasAuth === false && path_array.length > 0) {
          navigate(path_array[0]);
          return;
        };
        // 將使用者導入目前的URL
        navigate(curr_pathname);
        return;
      }
      return;

    }
    else if (call_getUserPerm.status === "err") {
      message.error(call_getUserPerm.msg);
      navigate("/login");
    }
  }, [call_getUserPerm.status]);
  // let { CheckAuth, path_array } = PermissionAuth({staffID:"123",peopleNM:"777",account:"abc",SDM:true,Translate:true});

  return (
    <HomeContent.Provider value={{ c_PNs, set_c_PNs}}>
      <Spin size='large' spinning={is_spinning}>
        <Layout style={{ height: "100vh" }}>
          <MyNavbar
            navbarState={navbarState}
            setNavbarState={setNavbarState}
            className="navbar"
            s_CheckAuth={s_CheckAuth}
          />
          <MainLayout navbarstate={navbarState}>
            <MyHeader
              className="header"
              navbarState={navbarState}
              setNavbarState={setNavbarState}
            />
            <Content className="content">

              {s_CheckAuth.length > 0 && (
                <div className="p-15px">
                  <Outlet />
                </div>
              )}

              {s_CheckAuth.length === 0 && (
                <ErrorPage />
              )}

            </Content>
          </MainLayout>
        </Layout>
      </Spin>
    </HomeContent.Provider>
  );
};

export default MyLayout;
