import {
  IconTechData,
  IconSalesShip, // 銷售發貨管理
  IconProductionQuery, // 生產資訊查詢
  IconSystemInformation, //權限資料維護
  IconBasicData, // 基本資料維護
  IconEquip, // 設備稼動總表
  IconDigital, //數位生產圖框
  IconDigitalKanban, // 數位生產看板
  IconProductionManagement, // 生管派工管理
  IconEquipment, //設備生產狀況
  IconException, // 異常管理看板
} from "../../components/Icon/Navbar";

import { NavLink } from "react-router-dom";
import {
  LineChartOutlined,
  ForkOutlined, //工程資料管理
} from "@ant-design/icons";

const iconStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "22px",
  height: "100%",
};

export function PermissionAuth(storeData) {
  console.log("storeData", storeData);

  //導覽列

  const items = [
    {
      label: (
        <NavLink to="/translate" className="NavLink">
          翻譯資料維護
        </NavLink>
      ),
      key: "translate",
      icon: <IconDigitalKanban style={iconStyle} />,
      children: null,
      auth: storeData.TRA,
      disabled: false,
      path: "/translate",
    },
    {
      label: "權限資料維護",
      key: "sub5",
      icon: <IconSystemInformation style={iconStyle} />,
      children: [
        {
          label: (
            <NavLink to="/account" className="NavLink NavLink-child">
              權限資料管理
            </NavLink>
          ),
          key: "account",
          children: null,
          auth: storeData.SDM,
          disabled: false,
          path: "/account",
        },
      ],
      auth: storeData.SDM,
      disabled: false,
      style: { borderBottom: "1px solid #f0f0f0" },
    },
  ];

  //items裡的物件auth屬性會先分別被c_Permission設定為 true or false
  //如果有children，也要分別檢查children裡各物件的auth為true or false
  //最後回傳 auth 皆為true的items1陣列，此陣列代表該登錄帳號所有可使用之權限

  const CheckAuth = items
    // 先判斷有沒有權限
    .filter((item) => Boolean(item.auth) === true)
    // 判斷子層有沒有權限
    .map((item) => {
      if (!item.children || item.children.length === 0) return item;

      const _children = item.children.filter(
        (children_item) => children_item.auth === true
      );

      return { ...item, children: _children };
    });

  let path_array = [];

  CheckAuth.forEach((item) => {
    if (item.children) {
      item.children.forEach((children) => path_array.push(children.path));
    } else {
      path_array.push(item.path);
    }
  });

  // console.log("CheckAuth", CheckAuth);

  return { CheckAuth, path_array };
}
