const FormLabel = ({children, disabled, ...props}) => {

  return (
    <label
      style={{ color: disabled ? 'rgba(0, 0, 0, 0.3)' : 'rgb(108, 108, 108)' }}
      {...props}
    >
      {children}
    </label>
  )
};

export default FormLabel;