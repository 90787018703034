export const IconBox = (props) => {
  return (
    <span
      role="img"
      aria-label="icon-press"
      className={`anticon ${props.className}`}
      onClick={!props.disabled ? props.onClick : null}
      style={{
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        fontSize: '24px',
        ...props.style,
      }}
    >
      {<props.Icon style={{ color: props.disabled ? "#a8a8a8" : props.style?.color }} />}
    </span>
  )
}