export const lifeFkeyMap = {
  0: "已創建", //藍
  1: "執行中", // 綠
  2: "已提交", // 鴻
  W: "待規劃",
  R: "已歸還",
  T: "已異動", // 黑
};

export const opcodeTitleMap = {
  4110: "生產入庫", //生產入庫
  4120: "採購入庫", // 採購入庫
  4130: "銷售退回", //銷售退回
  4220: "銷售發貨", //銷售發貨
  4230: "進料退出", //進料退出
  4141: "庫房調入", // 庫房調入
  4241: "調撥出庫", //調撥出庫
  4242: "庫房借出", //庫房借出
  4143: "借出歸還", //借出歸還
  4142: "庫房借入", //庫房借入
  4243: "借入歸還", //借入歸還
  4210: "生產領用", //生產領用
  4299: "生管超領", //生產超領
  4150: "工程入庫", //工程入庫
  4160: "其他入庫", //其他入庫
};

export const opcodeOperNmap = {
  4110: "單號", //生產入庫
  4120: "採購單號", //採購入庫
  4130: "銷售單號", //銷售退回
  4220: "銷售單號", //銷售發貨
  4230: "退出單號", //進料退出
  4141: "對倉", // 庫房調入
  4241: "對倉", //調撥出庫
  4242: "借出單號", //庫房借出
  4143: "借出單號", //借出歸還
  4142: "借入單號", //庫房借入
  4243: "借入單號", //借入歸還
  4210: "領用單號", //生產領用
  4299: "領用單號", //生產超領
};

export const opcodeWhIDNmap = {
  4110: "單號", //生產入庫
  4120: "採購單號", //採購入庫
  4130: "銷售單號", //銷售退回
  4220: "銷售單號", //銷售發貨
  4230: "退出單號", //進料退出
  4141: "本倉", // 庫房調入
  4241: "本倉", //調撥出庫
  4242: "倉別", //庫房借出
  4143: "倉別", //借出歸還
  4142: "倉別", //庫房借入
  4243: "倉別", //借入歸還
};

export const opcodeInchargeIDmap = {
  4120: "負責採購",
  4130: "負責人員",
  4220: "負責人員",
  4230: "負責人員",
  4141: "負責人員",
  4241: "負責人員",
  4242: "負責人員",
  4143: "負責人員",
  4142: "負責人員",
  4243: "負責人員",
  4210: "負責人員",
  4299: "負責人員", //生產超領
};

export const opcodeDuedayMap = {
  4120: "入庫日期", //採購入庫
  4130: "入庫日期", //銷售退回
  4141: "預計入庫日期", // 庫房調入
  4220: "預計出庫日期", // 銷售發貨
  4230: "預計出庫日期", //進料退出
  4241: "預計出庫日期", //調撥出庫
  4242: "預計出庫日期", //庫房借出
  4143: "預計歸還日期", //借出歸還
  4142: "預計入庫日期", //庫房借入
  4243: "預計歸還日期", //借入歸還
  4210: "領用日期", //生產領用
  4299: "領用日期", //生產超領
};

export const opcodeBatchnoMap = {
  4120: "入庫批號", // 採購入庫
  4130: "入庫批號", //銷售退回
  4141: "入庫批號", // 庫房調入
  4143: "入庫批號", //借出歸還
  4142: "入庫批號", //庫房借入
  4243: "出庫批號", //借入歸還
  4220: "出庫批號", //銷售發貨
  4242: "出庫批號", //庫房借出
  4230: "出庫批號", //進料退出
  4241: "出庫批號", //調撥出庫
  4210: "出庫批號", //生產領用
  4299: "出庫批號", //生產超領
};

export const opcodedealIDMap = {
  4120: "廠商編號", // 採購入庫
  4220: "客戶編號", //銷售發貨
  4130: "客戶編號", //銷售退回
  4230: "廠商編號", //進料退出
  4241: "出庫批號", //調撥出庫
  4210: "廠商編號", //生產領用
  4299: "廠商編號", //生產超領
};

export const opcodedealNMMap = {
  4120: "廠商簡稱", // 採購入庫
  4220: "客戶簡稱", //銷售發貨
  4130: "客戶簡稱", //銷售退回
  4230: "廠商簡稱", //進料退出
  4241: "出庫批號", //調撥出庫
  4210: "廠商簡稱", //生產領用
  4299: "廠商簡稱", //生產超領
};

export const systemMap = {
  "ADM": "資料管理系統",
  "PDS": "戰情登錄系統",
  "WOS": "工單控制系統",
  "WMS": "倉儲管理系統",
  "IMS": "庫存管理系統",
  "PMS": "採購管理系統",
  "OMS": "銷售管理系統",
  "APS": "製造管理系統",
  "BPM": "自動簽核系統",
  "MNS": "管理推播系統",
  "QMS": "品質管理系統",
  "util": "公用翻譯",
  "error": "錯誤碼翻譯",
};

export const plantMap = {
  "": "工智聯",
  "YU": "優如",
  "CPT": "中耀",
  "CK": "振傑",
  "ZK": "兆科",
  "FFVN": "越南廠",
  "ALEX": "亞獵士",
}