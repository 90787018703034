import styled from 'styled-components';
import { fontSize } from '../../styles/BasicSetting';
import { Modal } from 'antd';

const StyleModal = styled(Modal)`

  // 圓角設定
  .ant-modal-content {
    border-radius: 5px;
  }

  // Modal title 下方的分隔線
  .ant-modal-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #F0F0F0;
  };

  /* .ant-modal-body {
    padding-bottom: 0px;
  }; */
  // Modal title
  .ant-modal-title {
    font-size: ${fontSize.h4};
    font-weight: 500
  };
  // 關閉 icon 的字體大小
  .ant-modal-close-x {
    font-size: ${fontSize.h4};
  };
`
const CustomModal = ({children, ...props}) => {
  //const {children} = props
  return (
    <StyleModal
      maskClosable={false}
      destroyOnClose
      footer={null}
      forceRender
      getContainer={false}
      {...props}
    >
      {children}
    </StyleModal>
  )
}
export default CustomModal;

