// WMS 異動時的 icon
import { ReactComponent as Circle } from '../../assets/icon/TableIcon/circle.svg';
// 編輯時的鉛筆
import { ReactComponent as Pen } from '../../assets/icon/TableIcon/newpen.svg';
// 刪除時的垃圾桶
import { ReactComponent as Garbage } from '../../assets/icon/TableIcon/garbage.svg';
// 員工資料管理(凍結按鈕)
import { ReactComponent as Lock } from '../../assets/icon/TableIcon/lock.svg';
// 員工資料管理(解凍按鈕)
import { ReactComponent as UnLock } from '../../assets/icon/TableIcon/unlock.svg';

import {ReactComponent as Excel } from '../../assets/icon/Office/icon-xlsDownload.svg'

import { CheckCircleOutlined } from "@ant-design/icons";

// import { ReactComponent as FunctionString } from '../../assets/icon/functionString.svg';
// import { ReactComponent as JobTitle } from '../../assets/icon/JobTitle.svg';

import {IconBox} from "./IconBox";


// WMS 異動時的 icon
export const IconCircle = (props) => <IconBox {...props} Icon={Circle} />
// 刪除時的垃圾桶
export const IconGarbage = (props) => <IconBox {...props} Icon={Garbage} />
// 編輯時的鉛筆
export const IconPen = (props) => <IconBox {...props} Icon={Pen} />
// 員工資料管理(凍結按鈕)
export const IconLock = (props) => <IconBox {...props} Icon={Lock} />
// 員工資料管理(解凍按鈕)
export const IconUnLock = (props) => <IconBox {...props} Icon={UnLock} />

//Excel下載按鈕
export const IconExcel = (props) => <IconBox {...props} Icon={Excel} />

export const IconCheck = (props) => <IconBox {...props} Icon={CheckCircleOutlined} />

// export const IconFunctionString = (props) => <IconBox {...props} Icon={FunctionString} />
// export const IconJobTitle = (props) => <IconBox {...props} Icon={JobTitle} />

