const defaultColor = {
  "@tabs-ink-bar-color": "#7ab1f4",
  "@primary-color": "#7ab1f4", // 主題色
};

export const light = {
  ...defaultColor,
  "@text-color": "#6C6C6C", // 文字顏色 fade(@black, 85%)
  "@heading-color": "rgba(0,0,0,0.85)",
  "@text-color-secondary": "rgba(0,0,0,0.85)",

  "@component-background": "#FFF", // ex: 表格內容背景色、navbar背景色
  "@background-color-light": "#FFF",
  "@table-header-sort-bg": "#d9e7e4",
  "@select-item-selected-bg": "@primary-1",

  "@table-row-hover-bg": "rgba(253, 255, 203, .5)",
  "@table-header-color": "rgb(108, 108, 108)", // @heading-color
  "@table-header-bg": "rgb(192, 220, 240)",
  "@table-bg": "#f5f5f5",
  "@table-body-sort-bg": "#fafafa",

  "@layout-body-background": "#FFF", //#F0F2F5
  "@layout-header-background": "#fff",
  "@label-color": "rgb(108, 108, 108)",
  "@layout-sider-background": "@layout-header-background", //
  "@modal-heading-color": "@primary-color", // Modal style
  "@modal-close-color": "@primary-color", // Modal style
  "@collapse-header-bg": "#F0F2F5",
  "@collapse-header-color": "@primary-color",
  "@message-notice-content-bg": "#FFF2CC",
  // "@select-item-selected-color": "#0000FF",
};
export const dark = {
  "@primary-color": "#5995BC",
  "@text-color": "#ffffff",
  "@heading-color": "rgba(255,255,255,0.85)",
  "@text-color-secondary": "rgba(255,255,255,0.85)",

  "@page-header-back-color": "#ffffff",
  "@component-background": "#424242",

  "@table-row-hover-bg": "#7d7d67",
  "@disabled-color": "#FFF",
  "@table-bg": "rgb(58, 71, 78)",
  "@disabled-color-dark": "red",
  "@background-color-light": "#2b587b",
  "@background-color-base": "rgba(20, 77, 107 , 0.5)",
  "@table-header-sort-bg": "@background-color-base",
  "@label-color": "#FFF",
  "@select-item-selected-bg": "rgba(0, 150, 136, 0.4)",
  "@item-hover-bg": "#f5f5f5",

  "@select-item-selected-color": "#FFFF00",

  "@table-body-sort-bg": "#526772",
  "@table-header-color": "#ffffff",
  "@table-header-bg": "@background-color-light",

  // "@menu-dark-bg": "#0f172a ",
  // "@menu-dark-submenu-bg": "#0f172a",

  "@modal-heading-color": "@primary-color", // Modal style
  "@modal-close-color": "@primary-color", // Modal style

  "@message-notice-content-bg": "#FFF2CC",

  "@layout-body-background": "#000000",
  "@layout-header-background": "#424242",
  "@layout-sider-background-light": "#0f172a",

  "@border-color-split": "#6C6C6C",
  "@border-color-base": "#6C6C6C",
  "@divider-color": "#6C6C6C",
  ...defaultColor,
};
// export const light = {
//   //mainColor: "#58beb3", //主色
//   mainColor: "#71AFF8", //主色
//   cardBg: "#F9F9F9", // 生產看板bg
//   Color1: "#71AFF8", //副色1
//   Color2: "#70CEF6", //副色2
//   Color3: "#5e86c1",
//   Color4: "#c4dcec", //#b9ddf5
//   Color5: "#6F7C98",
//   wlFlag: "#4278DF", //生產看板旗標顏色
//   error: "#d86363", //錯誤
//   warning: "#EBB549", //警告
//   success: "#699f5b", //成功
//   pageBg: "#ffffff", //整體背景
//   componentsBg: "#ffffff", // 區塊背景
//   retext: "rgba(255,255,255,0.8)", //有背景時文字顏色
//   text: "rgba(0,0,0,0.85)", //文字顏色
//   text1: "#6C6C6C",
//   text2: "rgba(0,0,0,0.85)", //文字顏色
//   text3: "#FFF",
//   borderColor: "#f0f0f0",
//   shadowColor: "#8D8D8D",
//   disabledColor: "rgb(219, 217, 217)",
//   disabledTextColor: "#c5c5c5",
//   dashBox: "#F9F9F9",
//   tableBodyBorder: "rgb(217, 217, 217)",
//   inputBorderColor: "#d9d9d9",
//   modalBodyBg: "#FFF",
//   SysteamName: "#315ae7",
//   DashBoardCardInnfoText: "#0346FF",
//   HeaderTag: "#398eef",
//   HeaderTime: "#398eef",
//   menuIcon: "inherit",
//   dashBoardCard: "#71AFF8",
//   tableRowLight: "#FFFFFF",
//   tableRowDark: "#F0F0F0",
// };

// export const dark = {
//   mainColor: "#C0DCF0",
//   Color1: "#C0DCF0",
//   Color2: "#8bd1c8",
//   Color3: "#F9F9F9",
//   Color4: "#2b587b",
//   Color5: "#71AFF8",
//   cardBg: "#292929", // 生產看板bg
//   header: "#000",
//   wlFlag: "#4278DF", //生產看板旗標顏色
//   modalColor: "#5995BC",
//   pageBg: "rgba(255,255,255,0.8)",
//   componentsBg: "#fafafa",
//   retext: "rgba(255,255,255,0.8)",
//   text: "#FFF",
//   text1: "#FFF",
//   text2: "rgba(0,0,0,0.85)", //文字顏色
//   text3: "#000",
//   headerColor: "#272727",
//   mainBg: "#2D2D2D",
//   dashBox: "#4B4848",
//   disabledColor: "#a9a9a9",
//   tableBodyBorder: "#000",
//   inputBorderColor: "#5995BC",
//   modalBodyBg: "#424242",
//   SysteamName: "#FFF",
//   DashBoardCardInnfoText: "#00F6FF",
//   HeaderTag: "#FFF",
//   HeaderTime: "yellow",
//   menuIcon: "yellow",
//   dashBoardCard: "#22608B",
//   tableRowLight: "",
//   tableRowDark: "",
// };

export const change_antd_theme = (color) => {
  window.less
    .modifyVars(color)
    .then(() => {
      console.log("更換主題成功");
    })
    .catch((error) => {
      console.log("更換主題失敗", error);
    });
};
