import { createContext } from "react";

// interface authType {
//   MC: string;
//   OUT: string;
//   PDT: string;
//   QC: string;
//   SFC: string;
//   WOC: string;
// }

// interface wlType {
//   wlID: string
//   wlNM: string
//   wsID: string
//   wsNM: string
// }

// //全域參數
// interface theWholeArea {
//   theme: string;
//   auth: authType;
//   wl: string;
//   ws: string;
//   wlArr:Array<wlType>;
// }

// //
// interface storeContextType {
//   state: theWholeArea;
//   setState: React.Dispatch<React.SetStateAction<theWholeArea>>;
// }
// export type { theWholeArea, wlType };

const StoreContext = createContext({});
export default StoreContext;
