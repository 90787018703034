import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, message, Row, Col, Select } from "antd";

// import logo from "../../assets/img/logo.png"; // relative path to image
import logo from "../../assets/img/logo_company.png"; // relative path to image
import loginSvg from "../../assets/img/loginbg.svg";
//import './index.css'
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "../../service/api/publicAPI";
import Cookies from "js-cookie";
import { plantMap } from '../../util/constants'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginSvg});
  background-size: cover;
  //background-repeat: no-repeat;
  background-position: center 110px;
  background-position: left;
  background-repeat: no-repeat;
  background-color: rgb(40, 98, 139);
  position: relative;

  .img-box {
    /* background-color: red; */
    width: 230px;
    height: 76px;
    position: absolute;
    top: 160px;
    left: 100px;
  }
  .img-box img {
    width: 230px;
    height: 76px;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
const Box = styled.div`
  position: absolute;
  top: 130px;
  left: 70px;
  width: 650px;
  height: 70%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1440px) {
    width: 550px;
  }
`;
const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 12px;
  font-family: Microsoft JhengHei;
  text-align: center;
`;
const StyledForm = styled(Form)`
  // 客製化輸入框寬度設定100
  .w-100 {
    width: 100%;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
  }

  .ant-input {
    font-size: 20px;
  }
  .ant-input-affix-wrapper {
    height: 50px;
  }

  .ant-input-suffix {
    font-size: 20px;
    color: #bebebe;
  }

  .ant-input::placeholder {
    color: #bebebe;
    font-size: 20px;
  }

  .ant-select-selector {
    border-radius: 5px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }
`;

function Login() {
  const [form] = Form.useForm();

  const [buttonLodaing, setButtonLoading] = useState(false);

  const onFinish = async (values) => {
    setButtonLoading(true);
    console.log(values);
    Cookies.set("plantID", values.plantID);
    const res = await login(values);
    setButtonLoading(false);

    if (res.status) {
      window.location.assign("/");
    } else {
      console.log("values = ", values);
      console.log("res = ", res);
      message.error(res.msg);
    }
  };

  return (
    <Wrapper>
      <div className="img-box">
        <img src={logo} alt="系統資料管理" />
      </div>

      <Box>
        <Title>帳戶登錄 - 翻譯系統</Title>
        <StyledForm
          onFinish={onFinish}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="account" // account
                rules={[
                  { required: true, message: "請輸入正確的用戶名或郵件" },
                ]}
              >
                <Input
                  placeholder="請輸入帳號"
                  prefix={
                    <UserOutlined
                      style={{
                        fontSize: "20px",
                        color: "#BEBEBE",
                        marginRight: "20px",
                      }}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="pwd"
                rules={[{ required: true, message: "請輸入正確的密碼" }]}
              >
                <Input.Password
                  placeholder="請輸入密碼"
                  prefix={
                    <LockOutlined
                      style={{
                        fontSize: "20px",
                        color: "#BEBEBE",
                        marginRight: "20px",
                      }}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="plantID"
                // rules={[{ required: true, message: "請選擇廠別" }]}
              >
                <Select
                size="large"
                options={
                  Object.keys(plantMap).map((plantID) => {
                  return (
                    {
                      value: plantID,
                      label: plantMap[plantID],
                    }
                  );
                })}
                >
                  {/* <Select.Option value="">工智聯</Select.Option>
                  <Select.Option value="YU">優如</Select.Option>
                  <Select.Option value="CPT">中耀</Select.Option>
                  <Select.Option value="CK">振傑</Select.Option>
                  <Select.Option value="ZK">兆科</Select.Option>
                  <Select.Option value="FFVN">越南廠</Select.Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    width: "100%",
                    height: "60px",
                    backgroundColor: "#A3D3E8",
                    border: "1px solid #A3D3E8",
                    borderRadius: "5px",
                    fontSize: "20px",
                  }}
                  loading={buttonLodaing}
                >
                  登入
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </StyledForm>
      </Box>
    </Wrapper>
  );
}
export default Login;
