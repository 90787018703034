import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';

import FormLabel from "../../components/Custom/FormLabel";
import CustomForm from "../../components/antdCustom/CustomForm";

import useAPI from "../../hooks/useAPI";
import { updateAccount, addAccount } from '../../service/api/publicAPI';

const ModalContainer = ({ set_s_showModal, s_editData, set_s_editData }) => {
  const call_addAccount = useAPI(addAccount)
  const call_updateAccount = useAPI(updateAccount)

  const [form] = Form.useForm()

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "edit";

    const valusWithJSON = {
      account:values.account,
      pwd:values.pwd,
      auth:JSON.stringify({
        TRA:values.TRA,
        SDM:values.SDM,
        enEdit:values.enEdit,
        viEdit:values.viEdit,
        thEdit:values.thEdit,
        idEdit:values.idEdit,
      })
    }

    if (type === "create") {
      call_addAccount.request(valusWithJSON)
    }

    if (type === "edit") {
      call_updateAccount.request(valusWithJSON)
    }
  };

  // 新增
  useEffect(() => {
    if(call_addAccount.status === 'suc') {
      message.success(call_addAccount.msg);
      set_s_showModal(false);
    }
    else if(call_addAccount.status === 'err') {
      message.error(call_addAccount.msg);
      set_s_showModal(false);
    }
  }, [call_addAccount.status]);

  // 修改
  useEffect(() => {
    if(call_updateAccount.status === 'suc') {
      message.success(call_updateAccount.msg);
      set_s_showModal(false);
    }
    else if(call_updateAccount.status === 'err') {
      message.error(call_updateAccount.msg);
      set_s_showModal(false);
    }
  }, [call_updateAccount.status]);

  const handleCancel = () => {
    set_s_showModal(false)
  }

  useEffect(() => {
    form.resetFields()
  }, [form, s_editData]);

  return (
    <CustomForm
      onFinish={onSubmit}
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      initialValues={{
        TRA:true,
        SDM:true,
        enEdit:true,
        viEdit:true,
        thEdit:true,
        idEdit:true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item 
            label={<FormLabel>帳號</FormLabel>}
            name="account"
            rules={[{ required: true, message: '請填寫帳號!' }]}
          >
            <Input 
              disabled={Object.keys(s_editData).length > 0}
              placeholder="請輸入帳號"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label={<FormLabel>密碼</FormLabel>}
            name="pwd"
            rules={Object.keys(s_editData).length === 0 && [{ required: true, message: '請填寫密碼!' }]}
          >
            <Input 
              placeholder="請輸入密碼"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item 
            label={<FormLabel>翻譯資料管理</FormLabel>}
            name="TRA"
            // rules={[{ required: true, message: '請填寫所屬系統!' }]}
          >
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>關閉</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item 
            label={<FormLabel>權限資料管理</FormLabel>}
            name="SDM"
            // rules={[{ required: true, message: '請填寫所屬系統!' }]}
          >
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>關閉</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
        <Col span={6}>
          <Form.Item 
            label={<FormLabel>英文編輯</FormLabel>}
            name="enEdit"
          >
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>關閉</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item 
            label={<FormLabel>越南文編輯</FormLabel>}
            name="viEdit"
          >
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>關閉</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item 
            label={<FormLabel>泰文編輯</FormLabel>}
            name="thEdit"
          >
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>關閉</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item 
            label={<FormLabel>印尼文編輯</FormLabel>}
            name="idEdit"
          >
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>關閉</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col offset={12} span={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="large"
              style={{ marginRight: "20px", borderRadius: "5px" }}
              onClick={handleCancel}
            >
              取消
            </Button>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ borderRadius: "5px" }}
              >
                確定
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </CustomForm>
  )
}

export default ModalContainer;