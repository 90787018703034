import { useState, useEffect } from "react";
import { ResetStyle, GlobalStyle } from "./styles/globalStyles";
import { ThemeProvider } from "styled-components";

import zhTW from "antd/lib/locale/zh_TW";
import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

import Cookies from "js-cookie";

import moment from "moment";
import "moment/locale/zh-tw";

import MyRoutes from "./routes";

//初始值
import StoreContext from "./components/Context/StoreContext";

//antd theme
import { light, dark, change_antd_theme } from "./styles/antdTheme/theme";
//styled theme
import { styledLight, styledDark } from "./styles/styledTheme";

// 權限在Layout
const defaultStoreConfig = {
  TRA:true,
  SDM:true,
  enEdit:true,
  viEdit:true,
  thEdit:true,
  idEdit:true,
  account: "",
  peopleNM: "",
  pushToken: "",
  pwd: "",
  staffID: "",
  updtT: "",
};

function App() {
  const [storeConfig, setStoreConfig] = useState(defaultStoreConfig);
  
  const [ s_theme, set_s_theme ] = useState(Cookies.get('theme'));
  
  //default setting
  useEffect(() => {
    moment.locale("zh_tw");
  }, []);

  // theme change
  useEffect(() => {

    if(s_theme === 'dark') {
      change_antd_theme(dark);
    }
    else {
      change_antd_theme(light);
    }
  }, [s_theme]);

  useEffect(() => {
    const theme = Cookies.get("theme");
    if(theme === 'dark') {
      change_antd_theme(dark);
    }
  }, []);

  return (
    <div className="App">                                                           
      <ResetStyle />
      <GlobalStyle />
      <ConfigProvider locale={zhTW}>
        <StoreContext.Provider
          value={{ storeData: storeConfig, setStoreData: setStoreConfig, s_theme, set_s_theme }}
        >
          <ThemeProvider
            theme={s_theme === "dark" ? styledDark : styledLight}
          >
            <MyRoutes />
            {/* <Test /> */}
          </ThemeProvider>
        </StoreContext.Provider>
      </ConfigProvider>
    </div>
  );
}

export default App;
