import React, { useState, useEffect, useContext, useRef } from 'react';
import useAPI from "../../../hooks/useAPI";

import { Form, Button, Input, Modal, message } from 'antd';

import { PlusOutlined, ExclamationCircleOutlined, CheckOutlined, ReloadOutlined } from '@ant-design/icons';

import OperateArea from '../../../components/Custom/OperateArea';

import { DataContext } from '../index';
import StoreContext from "../../../components/Context/StoreContext";

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';

import { getTranslateTb,addTranslateTb,updateTranslateTb,deleteTranslateTb,getTranslateHd } from '../../../service/api/publicAPI';
import { timeCheckAndFormat } from '../../../util/dateFormat';
import Light from '../Light';

const innerWidth = window.innerWidth;

const TB = () => {
  const call_getTranslateTb = useAPI(getTranslateTb)
  const call_addTranslateTb = useAPI(addTranslateTb)
  const call_updateTranslateTb = useAPI(updateTranslateTb)
  const call_deleteTranslateTb = useAPI(deleteTranslateTb)

  const [scrollY, setScrollY] = useState("");
  const [ s_tableData, set_s_tableData ] = useState([]);
  const [ s_searchData, set_s_searchData ] = useState("");
  const [ is_spinning, set_is_spinning ] = useState(false);
  const { c_lightData, c_searchValue, call_getTranslateHd } = useContext(DataContext);
  const { storeData } = useContext(StoreContext)

  const EditableContext = React.createContext(null);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleAdd = () => {
    call_addTranslateTb.request({situF: "Y", pageID: c_lightData.pageID,sys: c_lightData.sys});
  };

  const handleSave = (row) => {
    call_updateTranslateTb.request(row)
  };

  // 刪除翻譯資料
  const handleDelete = (rowData) => {
    Modal.confirm({
      title: "翻譯資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的翻譯資料無誤，再進行刪除",
      okText: "確定",
      onOk:  () => {
        call_deleteTranslateTb.request(rowData);
      },
      cancelText: "取消",
    });
  };

  const tableHeaders = [
    {
      title: "燈號",
      dataIndex: "situF",
      align: "center",
      render: (text) => <Light color={text} size="16px" />,
    },
    {
      title: '操作',
      dataIndex: 'render',
      align: 'center',
      render: (text, rowData, index) => (
        <div
          style={{ display: "flex", justifyContent: "center", fontSize: "16px" }}
        >
          <CheckOutlined
            onClick={() => handleSave({ ...rowData, situF: "G" })}
            style={{ marginRight: "5px" }}
          />
          <ReloadOutlined
            onClick={() => handleSave({ ...rowData, situF: "Y" })}
          />
        </div>
      )
    },
    {
      title: '分頁ID',
      dataIndex: 'pageID',
      align: 'center',
    },
    {
      title: '項序',
      dataIndex: 'SN',
      align: 'center',
      // render:(text,item,index) => index + 1 
    },
    {
      title: '項目ID',
      dataIndex: 'itemID',
      align: 'center',
      editable: storeData.superman,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '繁體中文',
      dataIndex: 'twNM',
      align: 'center',
      editable: storeData.superman,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '簡體中文',
      dataIndex: 'cnNM',
      align: 'center',
      editable: storeData.superman,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '英文',
      dataIndex: 'enNM',
      align: 'center',
      editable: storeData.enEdit,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '越南文',
      dataIndex: 'viNM',
      align: 'center',
      editable: storeData.viEdit,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '泰文',
      dataIndex: 'thNM',
      align: 'center',
      editable: storeData.thEdit,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '印尼文',
      dataIndex: 'idNM',
      align: 'center',
      editable: storeData.idEdit,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: "備註",
      dataIndex: "note",
      align: "center",
      editable: true,
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: "更新人員",
      dataIndex: "updtID",
      align: "center",
    },
    {
      title: "更新時間",
      dataIndex: "updtT",
      align: "center",
      render:(text) => timeCheckAndFormat(text),
    },
    {
      title: '刪除',
      dataIndex: 'delete',
      align: 'center',
      render: (text, rowData, index) => (
        <OperateArea 
          // onEditClick={() => editSuppTb(rowData)}
          onDeleteClick={() => handleDelete(rowData)}
          deleteDisabled={!storeData.superman} // 不是superman不能刪除
        />
      )
    }
  ]

  // 自動調整寬度
  const tableColumns = tableHeaders.map((item)=>{
    if (["sn","render","situF", "delete"].includes(item.dataIndex)){
      item.width = `5%`
    }else{
      item.width = `${parseInt((100-20)/(tableHeaders.length-4))}%`
    }

    console.log(item.width);
    return item
  })

  const columns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  // 取得表身資料
  useEffect(() => {
    call_getTranslateTb.request({
      pageID: c_lightData.pageID,
      sys: c_lightData.sys,
      twNM:s_searchData,
    });
  }, [c_lightData,s_searchData]);

  // 表身有資料、RWD 時計算高度
  useEffect(() => {
    const ROWHEIGHT = 30;
    
    // 一般螢幕尺寸
    if(innerWidth >= 1920) {
      // 沒有表身資料
      setScrollY(ROWHEIGHT * 9);
    }
    // macbook 尺寸
    else if(innerWidth >= 1440) {
      setScrollY(ROWHEIGHT * 12);
    }
    else {
      setScrollY(ROWHEIGHT * 15);
    }

  }, [innerWidth, c_lightData]);

  // handle API status, 只有get會set table data
  useEffect(() => {
    if(call_getTranslateTb.status === 'suc') {
      set_is_spinning(false)
      set_s_tableData(call_getTranslateTb.data)
    }
    if(call_getTranslateTb.status === 'err') {
      set_is_spinning(false)
      message.error(call_getTranslateTb.msg);
    }
    if(call_getTranslateTb.status === 'load') {
      set_is_spinning(true)
    }
  }, [call_getTranslateTb.status]);

  useEffect(() => {
    if(call_addTranslateTb.status === 'suc') {
      set_is_spinning(false)
      message.success(call_addTranslateTb.msg);
      call_getTranslateTb.request({pageID: c_lightData.pageID,sys: c_lightData.sys, twNM:s_searchData})
      call_getTranslateHd.request(c_searchValue);
    }
    if(call_addTranslateTb.status === 'err') {
      set_is_spinning(false)
      message.error(call_addTranslateTb.msg);
    }
    if(call_addTranslateTb.status === 'load') {
      set_is_spinning(true)
    }
  }, [call_addTranslateTb.status]);

  useEffect(() => {
    if(call_updateTranslateTb.status === 'suc') {
      set_is_spinning(false)
      message.success(call_updateTranslateTb.msg);
      call_getTranslateTb.request({pageID: c_lightData.pageID,sys: c_lightData.sys, twNM:s_searchData});
      call_getTranslateHd.request(c_searchValue);
    }
    if(call_updateTranslateTb.status === 'err') {
      set_is_spinning(false)
      message.error(call_updateTranslateTb.msg);
    }
    if(call_updateTranslateTb.status === 'load') {
      set_is_spinning(true)
    }
  }, [call_updateTranslateTb.status]);

  useEffect(() => {
    if(call_deleteTranslateTb.status === 'suc') {
      set_is_spinning(false)
      message.success(call_deleteTranslateTb.msg);
      call_getTranslateTb.request({pageID: c_lightData.pageID,sys: c_lightData.sys, twNM:s_searchData});
      call_getTranslateHd.request(c_searchValue);
    }
    if(call_deleteTranslateTb.status === 'err') {
      set_is_spinning(false)
      message.error(call_deleteTranslateTb.msg);
    }
    if(call_deleteTranslateTb.status === 'load') {
      set_is_spinning(true)
    }
  }, [call_deleteTranslateTb.status]);

  return (
    <div>
      <Title title='翻譯資料明細'>
        <div className="flex-end">
          <Input.Search
            size="large"
            placeholder={"請輸入欲查詢的翻譯資料"}
            onSearch={(value) => set_s_searchData(value)}
            allowClear
            style={{ width: '300px' }}
            enterButton
          />
          <Button 
            onClick={handleAdd} 
            type="primary" 
            style={{borderRadius: '5px', marginLeft: "15px"}} 
            icon={<PlusOutlined />} 
            size='large'
            disabled={!storeData.superman} // 不是superman不能刪除
          >
            新增
          </Button>
        </div>
      </Title>
      
      <CustomTable 
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ y: scrollY }}
        loading={is_spinning}
        columns={columns}
        pagination={false}
        dataSource={s_tableData}
        rowClassName={(record, index) => {
          return index % 2 === 1 ? `dark editable-row` : `light editable-row`
        }}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
      />
    </div>
  )
};

export default TB;