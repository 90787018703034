import styled from "styled-components";
import { fontSize } from "../../styles/BasicSetting";


export const MyMainColorH1 = styled.h1`
  font-size: ${fontSize.h2};
  font-weight: 400;
  white-space: nowrap;
  margin: 0;
  color: ${({ theme }) => theme.Title};
`;