import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import MyLayout from "../components/Layout";

import Account from "../pages/Account";
import Translate from "../pages/Translate";

const MyRoutes = () => {
  // auth
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<MyLayout />}>
          <Route path="/translate" element={<Translate />} />
          <Route path="/account" element={<Account />} />

          {/* 未匹配 */}
          <Route path="*" element={<Navigate to="/ErrorPage" />} />
        </Route>
        <Route path="/login" element={<Login />} />
        {/* 未匹配 */}
        <Route path="*" element={<Navigate to="/login" />} />

      </Routes>
    </BrowserRouter>
  );
};
export default MyRoutes;
