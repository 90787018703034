const defaultColor = {
  error: "#FF0000", //錯誤
  reError: "#699F5B", // 錯誤回復
  warning: "#FFB300", //警告
  success: "#1BCF00", //成功
  info: "#0E7CF6",
  textShadow: "rgb(0 0 255 / 50%)", //文字陰影
};

export const styledLight = {
  activeTabColor: '#a8d2ff',
  Color4: '#c4dcec',
  mainColor: 'rgb(113, 175, 248)',
  pageBg: '#FFF',
  tableRowLight: '#FFFFFF', // 表格顏色
  tableRowDark: '#f0f0f0', // 表格顏色
  checkIcon: 'rgb(113, 175, 248)', // 是否有效的 icon
  DashBoardCardInnfoText: 'rgb(3, 70, 255)',
  closeIcon: 'gray', // 是否有效的 icon
  Header: '#FFF', // Header背景色
  systemColor: '#315ae7', // 系統名稱
  HeaderTag: 'rgb(57, 142, 239)', // 表頭資訊的顏色
  NavLink: 'rgb(108, 108, 108)', // navbar item 顏色
  antmenusub: '#FFF', // navbar sub menu 背景色
  navBarButton: 'rgb(108, 108, 108)', // 漢堡選單
  themeBtn: 'rgb(108, 108, 108)', // 日夜切換按鈕
  DashBoxbg: 'rgb(196, 220, 236)',
  DashBoxColor: 'rgb(108, 108, 108)',
  Title: 'rgb(113, 175, 248)', // Title 的顏色
  iconMore: '#7DBBF8', // 查看更多
  iconPen: '#7DB5C6', // 編輯
  iconCheck: '#4EC6AA', // 廠商確認數量
  iconCancel: '#6CA8D5', // 廠商取消數量
  iconReturn: '#7C8CE5',
  iconGarbage: '#EF4F4F', // 刪除
  iconMinus: '#FF9964',  // 強制結令
  iconReChange: '#7fc541', // 取消強制結令
  disabled: 'rgb(182, 182, 182)',
  iconDisabled: 'rgb(224, 221, 221)',
  ...defaultColor,
};

export const styledDark = {
  ...defaultColor,
  mainColor: 'rgb(192, 220, 240)',
  pageBg: 'rgba(255, 255, 255, 0.8)',
  checkIcon: 'rgb(192, 220, 240)', // 是否有效的 icon
  Header: 'rgb(66, 66, 66)', // Header背景色
  DashBoardCardInnfoText: 'rgb(3, 70, 255)',
  themeBtn: '#FFF', // 日夜切換按鈕
  systemColor: '#FFF',
  antmenusub: '#000', // navbar sub menu 背景色
  HeaderTag: '#FFF', // 表頭資訊的顏色
  navBarButton: '#FFF', // 漢堡選單
  NavLink: '#FFF', // navbar item 顏色
  DashBoxbg: 'rgb(43, 88, 123)',
  DashBoxColor: '#FFF',
};
