import styled from 'styled-components';
import { fontSize } from '../../styles/BasicSetting';
import { Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MyMainColorH1 } from './MyMainColorH1';


const TitleStyle = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.Color3};
  font-size: ${fontSize.h1};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ is_RWD }) => is_RWD ? 'start' : 'center'};

  .w-100 {
    width: 100%;
  }

  .RWD_Container {
    width: 100%;
    color: ${({ theme }) => theme.Title};
  }
  

  // ipad air(橫式)
  @media screen and (max-width: 1180px) {
    flex-direction: ${({ is_RWD }) => is_RWD ? 'column' : 'row'};
  }

  // ipad mini(橫式)
  @media screen and (max-width: 1024px) {
    flex-direction: ${({ is_RWD }) => is_RWD ? 'column' : 'row'};
  }

  // ipad air(直式)
  @media screen and (max-width: 820px) {
    flex-direction: ${({ is_RWD }) => is_RWD ? 'column' : 'row'};
  }

  // ipad mini(直式)
  @media screen and (max-width: 768px) {
    flex-direction: ${({ is_RWD }) => is_RWD ? 'column' : 'row'};
  }
  

  .flex-r {
    display: flex;
  }

  .Input {
    margin-right: 12px;
    width: 300px;
  }
  // 圓角設定
  .Input input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .Input .ant-input:focus {
    box-shadow: none
  }

  // 圓角設定
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  // 圓角設定
  .ant-select-selector, .ant-input-affix-wrapper {
    border-radius: 5px!important;
  }

  // 圓角設定
  .Input button {
    border-top-right-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
  }

  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.Color3 };
    color: #FFF;
    border: 2px solid ${({ theme }) => theme.Color3};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

const Title = ({children, ...props}) => {

  // RWD => 控制 page 要不要有 RWD 功能。

  const { title, placeholder, onSearch, handleChange, handleClick, is_RWD=false } = props;

  return (
    <TitleStyle is_RWD={is_RWD}>
      <MyMainColorH1>{title}</MyMainColorH1>
      {children ? (
        <div className={`${is_RWD === true ? `RWD_Container` : 'w-100'}`}>
          {children}
        </div>
        
      ) : (
        <div className="flex-r">
          <Input.Search 
            className='Input' 
            size='large' 
            placeholder={placeholder}
            onChange={e => handleChange(e.target.value)} 
            onSearch={onSearch} enterButton 
          />
          <Button 
            onClick={handleClick} 
            type="primary" 
            style={{borderRadius: '5px'}} 
            icon={<PlusOutlined />} 
            size='large'
          >
            新增
          </Button>
        </div>
      )}
    </TitleStyle>
  )
};

export default Title;