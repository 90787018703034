import styled from "styled-components";
import { fontSize } from '../../styles/BasicSetting';
import { Table } from "antd";

const StyleTable = styled(Table)`
  
  .border {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }

  .light {
    background-color: ${({ theme }) => theme.tableRowLight};
  }

  .dark {
    background-color: ${({ theme }) => theme.tableRowDark};
  }
  
  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 8px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.p};
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .ant-table-container {
    box-shadow: 0px .5px 3px #e7e2e2, 
    0px -1px 1px #e7e2e2;
    /* box-shadow: 0px 5px 5px black, */
    
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-body {
    border-bottom: ${({ theme }) => `.5px solid ${theme.tableBodyBorder}`};
    border-left: ${({ theme }) => `.5px solid ${theme.tableBodyBorder}`};
    border-right: ${({ theme }) => `.5px solid ${theme.tableBodyBorder}`};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  /* pagination-total-text  */
  .ant-pagination-total-text{
    font-size: ${fontSize.h4} !important;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0.6;
    margin-right: 15px;
    display: inline-block;
    color: ${({ theme }) => theme.text};
  }

  // 操作區域的所有 icon 的 layout
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: ${fontSize.text};
  }

  .icon-container .icon-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${fontSize.h4};
    height: ${fontSize.h4};
    font-size: ${fontSize.h5};
    cursor: pointer;
    color: #FFF;
    border-radius: 3px;
  }

  .not-allowed {
    cursor: not-allowed!important
  }

  .icon-more {
    background-color: ${({ theme }) => theme.iconMore};
  }

  .icon-pen {
    background-color: ${({ theme }) => theme.iconPen};
  }

  .icon-check {
    background-color: ${({ theme }) => theme.iconCheck};
  }

  .icon-cancel {
    background-color: ${({ theme }) => theme.iconCancel};
  }

  .icon-return {
    background-color: ${({ theme }) => theme.iconReturn};
  }

  .icon-garbage {
    background-color: ${({ theme }) => theme.iconGarbage};
  }

  .icon-minus {
    background-color: ${({ theme }) => theme.iconMinus};
  }

  .icon-disabled {
    background-color: rgb(224, 221, 221);
    color: rgb(182, 182, 182);
  }

  .icon-reChange {
    background-color: #7fc541;
  }

  /* .clickRowStyle > td {
    color: #FFAA00;
  }

  .clickRowStyle > td:nth-child(1) {
    border-left: 5px solid #FFAA00;
    padding-left: 3px;
  } */

  .clickRowStyle {
    background-color: rgba(253, 255, 203, .5);
  }

  // 是否有效的 icon
  .checkIcon {
    color: ${({ theme }) => theme.checkIcon};
    font-size: ${fontSize.h4};
  }

  // 是否有效的 icon
  .closeIcon {
    color: ${({ theme }) => theme.closeIcon};
    font-size: ${fontSize.h4};
  }

  .successIcon {
    color: ${({ theme }) => theme.checkIcon} !important;
  }

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    padding: 8px;
    font-size: ${({ size }) => size ? size : fontSize.h5};
    // 表身table的表頭要是深紫色
    color: ${({ type }) => type === 'dark-purple' ? '#FFF' : ''};
    background-color: ${({ type }) => type === 'dark-purple' ? '#6A68A1' : ''};
    white-space: nowrap;
  }

  .disabledRowStyle {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #ebe7e7!important;
  }

  .center {
    text-align: center;
  }

  .icon {
    cursor: pointer;
  }

  .hidden {
    display: none
  }

  .fileLink {
    color: ${({ theme }) => theme.Color3};
    cursor: pointer;
  }

  .row-min-width {
    min-width: 100px;
  }

  .lifeF-0 {
    color:#000;
    background-color: #dfe7f9
  }

  .lifeF-1 {
    color:#000;
    background-color: #adddb6
  }

  .lifeF-2 {
    color:#000;
    background-color: #ddadad
  }

  .lifeF-T {
    color:#000;
  }

  .editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    text-align: left;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;
const CustomTable = ({ columns, dataSource, className, rowClassName, size, ...props }) => {

  let data = []
  try {
    if (dataSource.length < 0 || !Array.isArray(dataSource)) {
      data = []
    } else {
      data = dataSource
    }
  } catch (e) {
    data = []
  }

  const newCol = columns.map((item, index) => {
    
    return {
      ...item,
      width: item.width ? item.width : null,
      className: item.className ? `${item.className}` : "",
    };
  });

  return (
    <StyleTable
      {...props}
      size={size}
      columns={newCol}
      dataSource={data}
      className={className}
      rowClassName={rowClassName ? rowClassName : (record, index) => {
        return index % 2 === 1 ? `dark` : `light`
      }}
    />
  );

};

export default CustomTable;
