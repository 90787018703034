
import React, {useState} from 'react';
import styled from 'styled-components';

import HD from './HD';
import TB from './TB';
import useAPI from "../../hooks/useAPI";
import { getTranslateHd } from '../../service/api/publicAPI';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;

`

export const DataContext = React.createContext();

const Translate = () => {
  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料
  const [ c_searchValue, set_c_searchValue ] = useState({
    searchValue:"",
  });
  const call_getTranslateHd = useAPI(getTranslateHd);
  
  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData, c_searchValue, set_c_searchValue, call_getTranslateHd}}>
      <Wrapper>
        
        <div className='flex_1'>
          <HD />
        </div>
        
        <div className='flex_1'>
          {/* 表身 */} 
          {Object.keys(c_lightData).length > 0 ? (
            <TB />
          ) : null}   
        </div>

      </Wrapper>
    </DataContext.Provider>
  )
};

export default Translate;