import React, {
  useState,
  useEffect,
  useContext
} from "react";
import { Modal, message, Input, Button } from "antd";
import { MySubtitleH4 } from "../../components/Custom/MySubtitleH4";
import styled from "styled-components";

import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";

import Title from "../../components/Custom/Title";
import CustomTable from "../../components/antdCustom/CustomTable";
import CustomModal from "../../components/antdCustom/CustomModal";
import OperateArea from "../../components/Custom/OperateArea";

import useAPI from "../../hooks/useAPI";
import { getAccount, deleteAccount } from '../../service/api/publicAPI';

import ModalContainer from "./ModalContainer";

import { parseAuthJSON } from "../../util/json"
import { timeCheckAndFormat } from "../../util/dateFormat"

const Wrapper = styled.div`
  .header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-r {
    display: flex;
    justify-content: flex-end;
  }

  // ipad air(橫式)
  @media screen and (max-width: 1180px) {
  }

  // ipad mini(橫式)
  @media screen and (max-width: 1024px) {
  }

  // ipad air(直式)
  @media screen and (max-width: 820px) {
    .header-container {
      justify-content: flex-end;
    }
  }

  // ipad mini(直式)
  @media screen and (max-width: 768px) {
    .header-container {
      justify-content: flex-end;
    }
  }
`;

const Account = () => {
  const call_getAccount = useAPI(getAccount);
  const call_deleteAccount = useAPI(deleteAccount);

  const innerWidth = window.innerWidth;
  const [scrollY, setScrollY] = useState("");
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_searchValue, set_s_searchValue] = useState({
    s_searchValue:""
  });
  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog
  const [s_editData, set_s_editData] = useState({});
  const [s_isLoading, set_s_isLoading] = useState(false); // api尚未回來時讓ui處於加載狀態

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
  });

  // 搜尋權限資料
  const handleSearch = (type, value) => {
    console.log(value);
    let tmp = {}

    // if(type === 'time') {
    //   tmp = { ...s_searchValue, 'startDate': value[0], 'endDate': value[1] };
    // } else {
    tmp = { ...s_searchValue, [type]: value };
    // }
    
    set_s_searchValue(tmp);
    setPageInfo({...pageInfo,currentPage:1})
  };

  //新建員工權限資料
  const createStaff = () => {
    set_s_showModal(true);
  };

  // 修改員工權限管理
  const handleEdit = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  // 刪除戰情登錄權限
  const handleDelete = (rowData) => {
    Modal.confirm({
      title: "權限資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的權限資料無誤，再進行刪除",
      okText: "確定",
      onOk: async () => {
        call_deleteAccount.request(rowData);
      },
      cancelText: "取消",
    });
  };

  //表格格式
  const tableHeaders = [
    {
      title: "員工帳號",
      dataIndex: "account",
      align: "center",
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: "翻譯資料維護",
      dataIndex: "TRA",
      align: "center",
      render: (text, rowData, index) => {
        const key = rowData.TRA;
        return {
          props: {
            style: { backgroundColor: key === null ? "#dbd9d9" : "" },
          },
          children: (
            <div className="checkIcon">
              {key === false ? (
                <CloseCircleOutlined style={{ color: "gray" }} />
              ) : key === true ? (
                <CheckCircleFilled className="successIcon" />
              ) : null}
            </div>
          ),
        };
      },
    },
    {
      title: "權限資料維護",
      dataIndex: "SDM",
      align: "center",
      render: (text, rowData, index) => {
        const key = rowData.SDM;
        return {
          props: {
            style: { backgroundColor: key === null ? "#dbd9d9" : "" },
          },
          children: (
            <div className="checkIcon">
              {key === false ? (
                <CloseCircleOutlined style={{ color: "gray" }} />
              ) : key === true ? (
                <CheckCircleFilled className="successIcon" />
              ) : null}
            </div>
          ),
        };
      },
    },
    {
      title: "英文編輯",
      dataIndex: "enEdit",
      align: "center",
      render: (text, rowData, index) => {
        const key = rowData.enEdit;
        return {
          props: {
            style: { backgroundColor: key === null ? "#dbd9d9" : "" },
          },
          children: (
            <div className="checkIcon">
              {key === false ? (
                <CloseCircleOutlined style={{ color: "gray" }} />
              ) : key === true ? (
                <CheckCircleFilled className="successIcon" />
              ) : null}
            </div>
          ),
        };
      },
    },
    {
      title: "越南文編輯",
      dataIndex: "viEdit",
      align: "center",
      render: (text, rowData, index) => {
        const key = rowData.viEdit;
        return {
          props: {
            style: { backgroundColor: key === null ? "#dbd9d9" : "" },
          },
          children: (
            <div className="checkIcon">
              {key === false ? (
                <CloseCircleOutlined style={{ color: "gray" }} />
              ) : key === true ? (
                <CheckCircleFilled className="successIcon" />
              ) : null}
            </div>
          ),
        };
      },
    },
    {
      title: "泰文編輯",
      dataIndex: "thEdit",
      align: "center",
      render: (text, rowData, index) => {
        const key = rowData.thEdit;
        return {
          props: {
            style: { backgroundColor: key === null ? "#dbd9d9" : "" },
          },
          children: (
            <div className="checkIcon">
              {key === false ? (
                <CloseCircleOutlined style={{ color: "gray" }} />
              ) : key === true ? (
                <CheckCircleFilled className="successIcon" />
              ) : null}
            </div>
          ),
        };
      },
    },
    {
      title: "印尼文編輯",
      dataIndex: "idEdit",
      align: "center",
      render: (text, rowData, index) => {
        const key = rowData.idEdit;
        return {
          props: {
            style: { backgroundColor: key === null ? "#dbd9d9" : "" },
          },
          children: (
            <div className="checkIcon">
              {key === false ? (
                <CloseCircleOutlined style={{ color: "gray" }} />
              ) : key === true ? (
                <CheckCircleFilled className="successIcon" />
              ) : null}
            </div>
          ),
        };
      },
    },
    {
      title: "更新時間",
      dataIndex: "updtT",
      align: "center",
      render:(text) => timeCheckAndFormat(text),
    },
    {
      title: "操作",
      dataIndex: "render",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <OperateArea
          onEditClick={() => handleEdit(rowData)}
          onDeleteClick={() => handleDelete(rowData)}
        />
      ),
    },
  ];

  // 自動調整寬度
  const tableColumns = tableHeaders.map((item)=>{
    item.width = `${parseInt(100/(tableHeaders.length))}%`

    return item
  })

  //關閉跳顯後動作
  useEffect(() => {
    if (!s_showModal) {
      call_getAccount.request({...s_searchValue})
    }
  }, [s_searchValue,s_showModal]);

  useEffect(() => {
    if(call_getAccount.status === 'suc') {
      const dataWithJSON = call_getAccount.data.map((item) => parseAuthJSON(item))

      set_s_isLoading(false)
      set_s_tableData(dataWithJSON)
    }
    if(call_getAccount.status === 'err') {
      set_s_isLoading(false)
      message.error(call_getAccount.msg);
    }
    if(call_getAccount.status === 'load') {
      set_s_isLoading(true)
    }
  }, [call_getAccount.status]);

  useEffect(() => {
    if(call_deleteAccount.status === 'suc') {
      message.success(call_deleteAccount.msg);
    }
    if(call_deleteAccount.status === 'err') {
      message.error(call_deleteAccount.msg);
    }
    call_getAccount.request({...s_searchValue})
  }, [call_deleteAccount.status]);

  return (
    <Wrapper>
      <Title title="權限資料管理">
        <div className="flex-r">
          <Input.Search
            className="Input"
            size="large"
            placeholder={"請輸入欲查詢的權限資料"}
            onSearch={(value) => handleSearch("searchValue",value)}
            allowClear
            enterButton
          />
          <Button
            onClick={createStaff}
            type="primary"
            style={{ borderRadius: "5px" }}
            icon={<PlusOutlined />}
            size="large"
          >
            新增
          </Button>
        </div>
      </Title>
      <CustomTable
        scroll={{ x: "max-content", y: scrollY }}
        size="17px"
        loading={s_isLoading}
        columns={tableColumns}
        dataSource={s_tableData}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,

          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size,
            });
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal
          width="60%"
          title={
            Object.keys(s_editData).length === 0 ? "新增權限資料" : "編輯權限資料"
          }
          visible={s_showModal}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer
            set_s_showModal={set_s_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
          />
        </CustomModal>
      ) : null}
    </Wrapper>
  );
};

export default Account;
