import moment from "moment";

export const dateFormat = (date) => {
  if (date === undefined || date === null || Array.isArray(date) === false) {
    return "暫無資料";
  } else {
    return `${date[0]}天${date[1]}時${date[2]}分`;
  }
};

export const timeCheckAndFormat = (time) => {
  return time === "0001-01-01T00:00:00Z"? "" : moment(time).format("MM-DD hh:mm")
}