import styled from "styled-components";
import { fontSize } from "../../styles/BasicSetting";
//副標題P
export const MySubtitleH4 = styled.h4`
  font-size: ${fontSize.h4} !important;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0.6;
  margin: 3px;
  display: inline-block;
  color: ${({ theme }) => theme.text};
`;