/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import StoreContext from "../../components/Context/StoreContext";
import moment from "moment";
import { Layout, Divider, Row, Col } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { IconSun, IconMoon } from "../Icon/Header";
import Cookies from "js-cookie";
import { light, dark, change_antd_theme } from "../../styles/antdTheme/theme";

import { fontSize } from "../../styles/BasicSetting";
import { plantMap } from "../../util/constants";

const { Header } = Layout;

const Time = () => {
  const [time, setTime] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(moment().format("YYYY-MM-DD"));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return <div style={{ fontWeight: "600" }}>{time}</div>;
};

const CustomHeader = styled(Header)`
  z-index: 2;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.1);
  /* background-color: #6A68A1; */
  height: 60px;
  position: fixed;
  /* padding: 0 15px; */
  padding: 0px;
  min-width: 100%;
  transition: 0.3s;
  top: 0;

  .container {
    background-color: ${({ theme }) => theme.Header};
  }

  .left-container {
    display: flex;
    justify-content: flex-start;
    height: 60px;
    padding-left: 15px;
    margin-left: ${({ navbarstate }) =>
      navbarstate === "open" ? "220px" : "0px"};
    transition: 0.3s;
  }

  .left-container {
    display: flex;
    align-items: center;
  }

  .navBarButton {
    font-size: ${fontSize.h2};
    color: ${({ theme }) => theme.navBarButton};
  }

  .left-container .systemName {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: ${fontSize.h2};
    font-weight: 400;
    color: ${({ theme }) => theme.systemColor};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .right-container {
    display: flex;
    justify-content: flex-end;
    height: 60px;
    overflow: hidden;
  }

  .HeaderTag {
    font-size: ${fontSize.h3};
    color: ${({ theme }) => theme.HeaderTag};
    overflow: hidden;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    white-space: nowrap;
    margin: 0;
  }

  .mr-15px {
    margin-right: 15px;
  }

  .themeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.themeBtn};
  }

  // ipad air(橫式)
  @media screen and (max-width: 1180px) {
  }

  // ipad mini(橫式)
  @media screen and (max-width: 1024px) {
  }

  // ipad air(直式)
  @media screen and (max-width: 820px) {
    .systemTime {
      display: none;
    }
    .systemTime-Divider {
      display: none;
    }
  }

  // ipad mini(直式)
  @media screen and (max-width: 768px) {
    .systemTime {
      display: none;
    }
    .systemTime-Divider {
      display: none;
    }
  }
`;

const MyHeaderEle = (props) => {
  const plantID = Cookies.get("plantID");

  const { storeData, setStoreData, s_theme, set_s_theme } = useContext(StoreContext);

  const change_sider_state = (e) => {
    stop_propagation(e);
    if (props.navbarState === "close" || props.navbarState === "shrinkClose") {
      props.setNavbarState("open");
    } else {
      props.setNavbarState("close");
    }
  };

  const stop_propagation = (e) => {
    e?.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    const closeSider = () => {
      props.setNavbarState("close");
    };

    if (props.navbarState === "open") {
      window.addEventListener("click", closeSider);
    }

    return () => {
      window.removeEventListener("click", closeSider);
    };
  }, [change_sider_state]);

  // const theme = {
  //   cursor: 'pointer',
  // }

  const chengeTheme = () => {
    if (s_theme === "dark") {
      change_antd_theme(light);
      set_s_theme("light");
      Cookies.set("theme", "light");
    } else {
      change_antd_theme(dark);
      set_s_theme("dark");
      Cookies.set("theme", "dark");
    }
  };

  return (
    <CustomHeader navbarstate={props.navbarState}>
      {/*  r_isNavBar={r_isNavBar} */}
      <Row wrap={false} className="container">
        <Col md={10} lg={12} span={12}>
          <div className="left-container">
            {props.navbarState === "open" ? (
              <MenuFoldOutlined
                className="navBarButton"
                onClick={change_sider_state}
              />
            ) : (
              <MenuUnfoldOutlined
                className="navBarButton"
                onClick={change_sider_state}
              />
            )}
            <div className="systemName">系統資料管理</div>
          </div>
        </Col>
        <Col md={14} lg={12} span={12}>
          <div className="right-container">
            <div className="Divider">
              <Divider
                type="vertical"
                style={{ height: "40px", width: "5px" }}
              />
            </div>

            <div className="userName HeaderTag">
              {storeData?.account ?? "測試帳號"}
            </div>

            <div className="Divider">
              <Divider
                type="vertical"
                style={{ height: "40px", width: "5px" }}
              />
            </div>

            <div className="systemTime HeaderTag">
              <Time />
            </div>

            <div className="Divider systemTime-Divider">
              <Divider
                type="vertical"
                style={{ height: "40px", width: "5px" }}
              />
            </div>

            <div className="plant HeaderTag mr-15px">
              {plantMap[plantID]}
            </div>

            <div className="Divider">
              <Divider
                type="vertical"
                style={{ height: "40px", width: "5px" }}
              />
            </div>

            <div className="HeaderTag themeBtn mr-15px">
              {/* <IconTheme /> */}
              {s_theme === "dark" ? (
                <IconMoon className="themeBtn" onClick={chengeTheme} />
              ) : (
                <IconSun className="themeBtn" onClick={chengeTheme} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </CustomHeader>
  );
};

export default MyHeaderEle;
