// 技術資料維護
import { ReactComponent as TechData } from '../../assets/icon/NavBar/icon-maintenanceOfTechnicalData.svg';
// 銷售發貨
import { ReactComponent as SalesShipmentManagement } from '../../assets/icon/NavBar/icon-salesShipmentManagement.svg';
// 生產資訊查詢
import { ReactComponent as ProductionQuery } from '../../assets/icon/NavBar/icon-productionInformationManagement.svg';
// 權限資料維護
import { ReactComponent as SystemInformation } from '../../assets/icon/NavBar/icon-systemInformationMaintenance.svg';
// 基本資料維護
import { ReactComponent as BasicData } from '../../assets/icon/NavBar/icon-basicDataMaintenance.svg';
// 設備稼動總表
import { ReactComponent as Equip } from '../../assets/icon/NavBar/icon-equipmentOperationSummary.svg';
// 數位生產看板
import { ReactComponent as DigitalKanban } from '../../assets/icon/NavBar/icon-digitalProductionKanban.svg';
// 數位生產圖框
import { ReactComponent as Digital } from '../../assets/icon/NavBar/icon-digitalProductionFrame.svg';
// 生管派工管理
import {ReactComponent as ProductionManagement} from '../../assets/icon/NavBar/icon-productionManagementDispatch.svg';
// 異常管理看板
import { ReactComponent as Exception } from '../../assets/icon/NavBar/icon-exceptionManagementKanban.svg';
// 設備生產狀況
import { ReactComponent as Equipment } from '../../assets/icon/NavBar/icon-equipmentProductionStatus.svg';

import {IconBox} from "./IconBox";

// 設備生產狀況
export const IconEquipment = (props) => <IconBox {...props} Icon={Equipment} />
// 技術資料維護
export const IconTechData = (props) => <IconBox {...props} Icon={TechData} />
// 銷售發貨
export const IconSalesShip = (props) => <IconBox {...props} Icon={SalesShipmentManagement} />
// 生產資訊查詢
export const IconProductionQuery = (props) => <IconBox {...props} Icon={ProductionQuery} />
// 權限資料維護
export const IconSystemInformation = (props) => <IconBox {...props} Icon={SystemInformation} />
// 基本資料維護
export const IconBasicData = (props) => <IconBox {...props} Icon={BasicData} />
// 設備稼動總表
export const IconEquip = (props) => <IconBox {...props} Icon={Equip} />
// 生管派工管理
export const IconProductionManagement = (props) => <IconBox {...props} Icon={ProductionManagement} />
// 數位生產圖框
export const IconDigital = (props) => <IconBox {...props} Icon={Digital} />
// 數位生產看板
export const IconDigitalKanban = (props) => <IconBox {...props} Icon={DigitalKanban} />
// 異常管理看板
export const IconException = (props) => <IconBox {...props} Icon={Exception} />
