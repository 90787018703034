import styled from "styled-components";
import { Form } from 'antd';

const StyleForm = styled(Form)`
  // 圓角設定
  .ant-btn, .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  // 圓角設定
  .ant-select-selector {
    border-radius: 5px!important;
  }

  .ant-form-item-label {
    padding-bottom: 6px;
  }

  // 上一筆、下一筆
  .outline-btn {
    color: #9FB2FC
  }

  // 上一筆、下一筆
  .outline-btn:hover {
    background-color: #dce2f7;
    /* border: 1px solid #c1e6fa; */
    color: #9FB2FC;
  }

  // 上一筆、下一筆
  .outline-btn:focus {
    /* background-color: #c1cdfa; */
    /* border: 1px solid #c1cdfa; */
    color: #9FB2FC;
  }
`

const CustomForm = ({children, ...props}) => {

  return (
    <StyleForm {...props}>
      {children}
    </StyleForm>
  )
};

export default CustomForm;